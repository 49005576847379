(<template>
  <section :class="{'in-progress': progress}"
           class="ent-settings-table is-progress-bar">
    <ent-settings-table-header :can-delete="canChange"
                               :can-edit="canChange"
                               :can-set-default="true"
                               :cols-list="desktopColsList" />
    <div :class="{'ent-settings-table__row--blacklisted': isBlacklisted}"
         class="ent-settings-table__row ent-settings-table__row--default">
      <ent-settings-table-row :item="paymentMethod"
                              :cols-list="desktopColsList"
                              :style="{display: 'none'}"
                              class="ent-settings-table__table-row ent-settings-table__table-row--desktop" />
      <ent-settings-table-row :item="paymentMethod"
                              :cols-list="mobileColsList"
                              :style="{display: 'none'}"
                              class="ent-settings-table__table-row ent-settings-table__table-row--mobile" />
      <div class="ent-settings-table__col ent-settings-table__col--default-actions">
        <p class="ent-settings-table__btn ent-settings-table__btn--default">{{ $gettext('Default payment method') }}</p>
      </div>
      <div v-if="canChange"
           class="ent-settings-table__col ent-settings-table__col--edit-actions">
        <button class="sk-btn sk-link sk-btn--like-link ent-settings-table__btn ent-settings-table__btn--edit"
                @click="editItem(paymentMethod)">{{ $gettext('Edit') }}</button>
      </div>
      <div v-if="canChange"
           class="ent-settings-table__col ent-settings-table__col--delete-actions">
        <button class="ent-settings-table__btn ent-settings-table__btn--remove"
                @click="deleteItem(paymentMethod.id)"></button>
      </div>
    </div>
    <div v-if="isBlacklisted"
         class="blacklisted-warning">{{ blacklistedWarningText }}</div>
  </section>
</template>)

<script>
  import EnterpriseSettingTableRow from '@/components/enterprise/shared/EnterpriseSettingTableRow';
  import EnterpriseSettingTableHeader from '@/components/enterprise/shared/EnterpriseSettingTableHeader';

  export default {
    components: {
      'ent-settings-table-header': EnterpriseSettingTableHeader,
      'ent-settings-table-row': EnterpriseSettingTableRow,
    },
    props: {
      progress: Boolean,
      canChange: Boolean,
      paymentMethod: {
        type: Object,
        default: () => {}
      },
      colsList: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      // can be refactor
      desktopColsList() {
        return this.colsList.filter((col) => {
          return col.showOn === undefined ? true : col.showOn === 'desktop';
        });
      },
      mobileColsList() {
        return this.colsList.filter((col) => {
          return col.showOn === undefined ? true : col.showOn === 'mobile';
        });
      },
      blacklistedWarningText() {
        return this.$gettext('This organization number cannot be used as an invoice recipient. Please update the Payment method.');
      },
      isBlacklisted() { return this.paymentMethod.isBlacklisted; }
    },
    methods: {
      editItem(item) {
        this.$emit('edititem', item);
      },
      deleteItem(id) {
        this.$emit('deleteitem', id);
      }
    }
  };
</script>

<style scoped src="@assets/css/enterprise_setting_table.css"></style>
